/**
 * Utils for handling iframe-related functionality
 */
import store from '../configureStore';

/**
 * Check if the application is running in an iframe
 */
export const isIframeMode = (): boolean => {
  try {
    return typeof window !== 'undefined' && window.self !== window.top && window.self !== null && window.top !== null;
  } catch (e) {
    // If we can't access window.top due to security restrictions, 
    // we're probably in an iframe
    return true;
  }
};

/**
 * Check if we should hide UI elements
 * Simplified to only use iframe detection
 */
export const shouldHideUIElements = (): boolean => {
  // Simply use the iframe detection method
  return isIframeMode();
};

/**
 * Extract authentication token from URL
 * This allows for automatic login when the iframe is loaded with a token parameter
 */
export const getTokenFromUrl = (): string | null => {
  if (typeof window === 'undefined') return null;
  
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
};

/**
 * Check if the URL contains an authentication token
 */
export const hasTokenInUrl = (): boolean => {
  return getTokenFromUrl() !== null;
};

/**
 * Basic validation of token format
 * This only checks basic structure, not cryptographic validity
 */
export const isValidTokenFormat = (token: string): boolean => {
  // Check if token is empty or only whitespace
  if (!token || token.trim() === '') return false;
  
  // If token contains a space (has type prefix like "bearer xyz")
  if (token.includes(' ')) {
    const parts = token.split(' ');
    // Must have exactly two parts (type and actual token)
    if (parts.length !== 2) return false;
    
    const tokenType = parts[0].toLowerCase();
    const tokenValue = parts[1];
    
    // Type must be one of the allowed types
    const validTypes = ['bearer', 'basic', 'jwt'];
    if (!validTypes.includes(tokenType)) return false;
    
    // Token value should not be empty
    if (!tokenValue || tokenValue.trim() === '') return false;
    
    // For JWT tokens (which should be the most common in our case)
    if (tokenType === 'bearer' || tokenType === 'jwt') {
      // JWT typically has 3 parts separated by dots
      if (!tokenValue.includes('.')) return false;
      
      // Very basic structure validation for JWT
      // Most JWTs have 3 segments separated by dots
      const jwtParts = tokenValue.split('.');
      if (jwtParts.length < 2) return false;
    }
  }
  
  return true;
};

/**
 * Handle automatic login if token is present in URL
 * Returns true if auto-login was attempted, false otherwise
 */
export const handleAutoLoginFromToken = (): boolean => {
  const token = getTokenFromUrl();
  
  if (!token) return false;
  
  try {
    // Validate token format before proceeding
    if (!isValidTokenFormat(token)) {
      console.error('Invalid token format detected, aborting auto-login');
      return false;
    }
    
    // Assume token format is "bearer TOKEN" or just "TOKEN"
    let tokenValue = token;
    let tokenType = 'bearer';
    
    // Check if token already contains token type (e.g. "bearer xyz123")
    if (token.includes(' ')) {
      const parts = token.split(' ');
      tokenType = parts[0].toLowerCase(); // Ensure token type is lowercase
      tokenValue = parts[1];
    }
    
    console.log('Auto-login: Storing token with type:', tokenType);
    
    // Set the token using direct localStorage setting as a fallback approach
    localStorage.setItem('Authorization', `${tokenType} ${tokenValue}`);
    
    // Attempt to call the auth.setToken action directly via store
    try {
      store.dispatch({
        type: '@action.auth.setToken',
        payload: { token: tokenValue, tokenType }
      });
    } catch (dispatchError) {
      console.error('Error dispatching action:', dispatchError);
      // Continue with localStorage approach if dispatch fails
    }
    
    // Clear the token from URL to prevent it from being visible
    // and to avoid repeated login attempts if the page is refreshed
    const url = new URL(window.location.href);
    url.searchParams.delete('token');
    
    // Replace current URL without the token parameter
    // This uses history.replaceState to avoid a page reload
    window.history.replaceState({}, document.title, url.toString());
    
    return true;
  } catch (error) {
    console.error('Error during auto-login process:', error);
    return false;
  }
};

/**
 * Apply iframe mode CSS classes to body
 */
export const applyIframeMode = (): void => {
  if (typeof document === 'undefined') return;
  
  if (shouldHideUIElements()) {
    document.body.classList.add('iframe-mode');
  } else {
    document.body.classList.remove('iframe-mode');
  }
};

/**
 * Remove iframe mode CSS classes from body
 */
export const removeIframeMode = (): void => {
  if (typeof document === 'undefined') return;
  document.body.classList.remove('iframe-mode');
}; 